<template>
  <div class="static-banner-box">
      <div class="img-box" :class="{'bg1':num==1,'bg2':num==2,'bg3':num==3,'bg4':num==4,'bg5':num==5}">
      </div>
  </div>
</template>

<script>
export default {
  name: 'BannerStatic',
  props:{
    num:{
      type:Number,
      default:0
    },
    title:{
      type:String,
      default:''
    }
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  beforeDestroy() {
  },
}
</script>

<style scoped lang="less">
.static-banner-box{
  .img-box{
    position: relative;
    height:7.733333rem /* 580/75 */;
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: center ;
  }
}
.bg1{
  background-image: url(../../assets/img/mstatic1.jpg)
}
.bg2{
  background-image: url(../../assets/img/mstatic2.jpg)
}
.bg3{
  background-image: url(../../assets/img/mstatic3.jpg)
}
.bg4{
  background-image: url(../../assets/img/mstatic4.jpg)
}
.bg5{
  background-image: url(../../assets/img/mstatic5.jpg)
}
</style>
