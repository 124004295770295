  <template>
    <div class="m-news-box">
         <bannerStatic :num="4" />
         <div class="new-warp m-minheight">
         <a-tabs :animated="false" class="tabstyle" >
           <a-tab-pane  v-for="(item,index) in listData" :key="index+1" :tab="item.moduleName" >
              <a-list class="info-list" itemLayout="vertical" size="small" :pagination="false" :dataSource="item.newsList">
                  <a-list-item slot="renderItem" slot-scope="item" :key="item.newsID" class="news-info-box" @click="jump(item.newsID)" >
                      <div class="img-box">
                          <img alt="郑大智能" :src="newsImg+item.newsImageUrl"   />
                      </div>
                      <div class="txt-box">
                          <p class="title-box">{{item.newsTitle}}</p>
                          <span class="time-box">{{moment(item.updatedTime).format('YYYY-MM-DD')}}</span>
                      </div>
                  </a-list-item>
                </a-list>
              </a-tab-pane>
            </a-tabs>
         </div>
    </div>
</template>
<script>
import bannerStatic from'@/components/BannerStatic/mBannerStatic'
import { getNews } from '@/api/data'
import  { newsImg } from '@/config/appSetting'
import moment from 'moment'
export default {
    name:'Mnews',
    components:{
      bannerStatic,
    },
    data() {
      return {
        newsImg,
        listData:[],
        typeList:[],
        // pagination: {
        //   simple:true,
        //   itemRender:(current, type, originalElement)=>{
        //         if (type === 'prev') {
        //         return <a>上一页</a>;
        //         } else if (type === 'next') {
        //         return <a>下一页</a>;
        //         }
        //         return originalElement;
        //   },
        //   onChange: page => {
        //     document.documentElement.scrollTop=0
        //   },
        //   pageSize: 6,
        // },
      };
    },
    methods: {
        moment,
        jump(id){
            this.$router.push({path:`/MNewDetails/${id}`})
        },
        getData(id){
        let _this=this
        this.listData=[]
            getNews({IsDisplay:id,CompanyType:2}).then(res => {
              if (res.state) {
                  if(res.data){
                     res.data.map((item)=>{
                      _this.listData.push(item)
                    })
                  }
              }
            })
        }
    },
    mounted() {
      this.getData(0)
    }  
}
</script>
<style lang="less" scoped>
.m-minheight{
    min-height: 60vh;
}
</style>
<style lang="less">
.m-news-box{
  background: #f6f9fb;
    .new-warp{
        .info-list{
          padding: 0 .333333rem /* 25/75 */;
        }
        .ant-list-pagination{
            text-align: center;
            margin: .533333rem /* 40/75 */ auto;
            .ant-pagination-simple {
                .ant-pagination-simple-pager{
                    input{
                        background: transparent;
                        border: 0;
                        margin-right: -5px; 
                        padding: 0; 
                        width:20px;
                    }
                    .ant-pagination-slash{
                      margin:0;
                    }
                  
                }
                .ant-pagination-prev{
                    background-image: linear-gradient(88deg, #1062e0 0%, #389bf5 100%);
                    border-radius: 26px;
                    width: 2.226667rem /* 167/75 */;
                    height: .706667rem /* 53/75 */;
                    line-height:.706667rem /* 53/75 */;
                    a{
                        color:#fff;
                        font-size: .32rem /* 24/75 */;
                    }
                }
                .ant-pagination-next {
                    background-image: linear-gradient(88deg, #1062e0 0%, #389bf5 100%);
                    border-radius: 26px;
                    color:#fff;
                    width: 2.226667rem /* 167/75 */;
                    height: .706667rem /* 53/75 */;
                    margin-left: 10px;
                    line-height:.706667rem /* 53/75 */;
                     a{
                        color:#fff;
                        font-size: .32rem /* 24/75 */;
                    }
                }
            }
        }
        .news-info-box{
            height: 2.666667rem /* 200/75 */;
            margin:.533333rem /* 40/75 */ 0;
            padding:.266667rem /* 20/75 */;
            overflow: hidden;
            box-sizing: border-box;
            // background: #fff;
            // box-shadow: 0px 0px 32px 0px rgba(28, 113, 207, 0.18);
            border-bottom: 0;
            .img-box{
                float: left;
                width:3.466667rem /* 260/75 */;
                height: 2.133333rem /* 160/75 */;
                overflow: hidden;
                border-radius: .133333rem /* 10/75 */;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit:cover;
                }
            }
            .txt-box{
                text-align: left;
                padding-left:3.866667rem /* 290/75 */;
                p{
                    height: 1.333333rem /* 100/75 */;
                    line-height: .666667rem /* 50/75 */;
                    overflow: hidden;
                    margin-bottom: .4rem /* 30/75 */;
                    color: #333333;
                    font-size: .373333rem /* 28/75 */;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
                .time-box{
                    color: #999999;
                    font-size: .32rem /* 24/75 */;
                }
            }
        }
    }
}
</style>
<style>
  .m-news-box  .ant-empty.ant-empty-normal {
    display: none;
  }
  .m-news-box  .ant-spin-container {
    min-height: 50vh;
  }
   .m-news-box .ant-tabs-nav-wrap{
    background: #fff;
  }
  .m-news-box .ant-tabs-nav .ant-tabs-tab{
    padding: 12px 3px ;
  }
  .m-news-box .ant-tabs-nav .ant-tabs-tab-active {
    color: #1890ff;
  }
  .m-news-box .ant-list-something-after-last-item .ant-spin-container > .ant-list-item:last-child{
    border: none;
  }
</style>
